import { useContext, useState, useEffect } from "react";
import DrugFavoritesContext from "../store/drug-favorites-context";
import Card from "./ui/Card";
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "./ui/Table";

function FDARecord(ndc) {
  const drugFavCTX = useContext(DrugFavoritesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedDrugs, setLoadedDrugs] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://three40b-drug-info-api.onrender.com/fda/product/${ndc.ndc}`)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setLoadedDrugs(data);
      });
  }, [ndc.ndc]);

  const drug = loadedDrugs;

  if (isLoading) {
    return <p>FDA NDC Directory Loading...</p>;
  }

  function nullCheck(item) {
    return item != null;
  }

  return (
    <Card>
      <section>
        <h2 className="text-xl font-bold text-gray-900 mb-4">
          FDA NDC Directory
        </h2>
      </section>
      {nullCheck(drug) && !nullCheck(drug["fda-error"]) ? (
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>NDC</TableHead>
              <TableHead>Labeler Name</TableHead>
              <TableHead>Proprietary Name</TableHead>
              <TableHead>Dosage Form</TableHead>
              <TableHead>Route</TableHead>
              <TableHead>Strength</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {drug.fda.map((d, index) => (
              <TableRow key={index}>
                <TableCell>{d.product_ndc}</TableCell>
                <TableCell>{d.labeler_name}</TableCell>
                <TableCell>{d.proprietary_name}</TableCell>
                <TableCell>{d.dosage_form}</TableCell>
                <TableCell>{d.route}</TableCell>
                <TableCell>{d.active_ingredients?.[0]?.strength}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      ) : (
        <p className="text-gray-500">{drug["fda-error"]}</p>
      )}
    </Card>
  );
}

export default FDARecord;
