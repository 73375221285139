import { useContext, useState, useEffect } from "react";
import DrugFavoritesContext from "../store/drug-favorites-context";
import Card from "./ui/Card";
import {
  Table,
  TableHeader,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "./ui/Table";

function MDRPRecord(ndc) {
  const drugFavCTX = useContext(DrugFavoritesContext);
  const [isLoading, setIsLoading] = useState(true);
  const [loadedDrugs, setLoadedDrugs] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    setIsLoading(true);
    fetch(`https://three40b-drug-info-api.onrender.com/mdrp/product/${ndc.ndc}`)
      .then((response) => response.json())
      .then((data) => {
        setIsLoading(false);
        setLoadedDrugs(data);
      })
      .catch((err) => {
        setIsLoading(false);
        setError("Failed to load MDRP data");
      });
  }, [ndc.ndc]);

  if (isLoading) {
    return <p className="text-gray-500">MDRP Data Loading...</p>;
  }

  if (error || !loadedDrugs || !loadedDrugs.mdrp) {
    return (
      <Card>
        <section>
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Medicaid Drug Rebate Program
          </h2>
          <p className="text-gray-500">
            {error || loadedDrugs?.["mdrp-error"] || "No MDRP data available"}
          </p>
        </section>
      </Card>
    );
  }

  return (
    <Card>
      <section>
        <h2 className="text-xl font-bold text-gray-900 mb-4">
          Medicaid Drug Rebate Program
        </h2>
      </section>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>NDC</TableHead>
            <TableHead>Drug Category</TableHead>
            <TableHead>Market Date</TableHead>
            <TableHead>Termination Date</TableHead>
            <TableHead>FDA Product Type</TableHead>
            <TableHead>Labeler Name</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {loadedDrugs.mdrp.map((d, index) => (
            <TableRow key={index}>
              <TableCell>{d.ndc}</TableCell>
              <TableCell>{d.drug_category}</TableCell>
              <TableCell>{d.market_date}</TableCell>
              <TableCell>{d.termination_date || "N/A"}</TableCell>
              <TableCell>{d.fda_product_name}</TableCell>
              <TableCell>{d.labeler_name}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

export default MDRPRecord;
